import { TypeFromSelection } from "groqd";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import { Block } from "../block";
import { Colorways } from "../../theme";
import { Testimonial } from "./Testimonial";
import { slidingTestimonialsCarouselSelection } from "./query";
import { Tabs } from "./Testimonial/Tabs";

export type TestimonialCarouselProps = TypeFromSelection<
  typeof slidingTestimonialsCarouselSelection
> & {
  colorway: Colorways;
};
export default function SlidingTestimonialsCarousel({
  colorway,
  config,
  testimonials,
}: TestimonialCarouselProps) {
  const totalSlides = testimonials.length;

  return (
    <CarouselProvider
      naturalSlideWidth={0}
      naturalSlideHeight={0}
      totalSlides={totalSlides}
      currentSlide={0}
      infinite={true}
      interval={config.animationInterval}
      isPlaying={true}
    >
      <Block fullWidth className="bg-transition-gradient ">
        <div className="container mx-auto">
          <Slider classNameAnimation="fade-animation">
            {testimonials.map((testimonial, i) => (
              <Slide
                index={i}
                key={i}
                classNameHidden="hide"
                classNameVisible="show"
              >
                <Testimonial
                  animationInterval={config.animationInterval}
                  colorway={colorway}
                  image={testimonial.image}
                  review={testimonial.review}
                  reviewer={testimonial.reviewer}
                  totalSlides={totalSlides}
                />
              </Slide>
            ))}
          </Slider>
          <div className="lg:ml-[33%]">
            <Tabs
              animationInterval={config.animationInterval}
              colorway={colorway}
              totalSlides={totalSlides}
            />
          </div>
        </div>
      </Block>
    </CarouselProvider>
  );
}
