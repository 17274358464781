export enum Backgrounds {
  Default = "default",
  Families = "families",
  Moms = "moms",
  None = "none",
  Pros = "pros",
  LightGray = "light-gray",
}

export enum HeadingStyle {
  H2 = "h2gradient",
  H3 = "h3dark",
}

export enum ImagePlacement {
  Left = "left",
  Right = "right",
}

export enum LayoutVariant {
  SingleColumn = "single-column",
  MultiColumn = "multi-column",
}
