import { Colorways } from "../../theme";

export const Heading = ({
  children,
  colorway,
}: {
  children: React.ReactNode;
  colorway: Colorways;
}) => (
  <h1
    className={`text-deco-head-2 lg:text-deco-head-1 mb-3 text-pretty text-${colorway}`}
  >
    <strong>
      <em>{children}</em>
    </strong>
  </h1>
);
