import Image from "next/image";
import { Quotes } from "./Quotes";
import { Colorways } from "../../../theme";
import { TestimonialsCarouselTab } from "../types";
import { getImageURL } from "../../../utils/getImageURL";
import { getImageDimensions } from "@sanity/asset-utils";

type Props = TestimonialsCarouselTab & {
  animationInterval: number;
  colorway: Colorways;
  totalSlides: number;
};
export const Testimonial = ({ colorway, image, review, reviewer }: Props) => {
  const { width, height } = getImageDimensions({
    ...image,
    asset: image.asset || null,
  });

  const widthCorrected = image.is2x ? width / 2 : width;
  const heightCorrected = image.is2x ? height / 2 : height;

  return (
    <div className="mx-auto mt-12 flex flex-col lg:w-5/6 lg:flex-row">
      <div className="my-auto lg:w-1/3">
        {!!image?.asset && (
          <Image
            width={widthCorrected}
            height={heightCorrected}
            style={{ margin: "0 auto" }}
            sizes="100vw"
            className={`max-w-full`}
            src={getImageURL(image).url()}
            alt="decorative image"
            quality={90}
          />
        )}
      </div>

      <div className={`flex flex-col text-${colorway} lg:w-2/3`}>
        <Quotes className="hidden lg:block" />

        <p className="text-quote-2 lg:text-quote-1 my-9 text-center text-onyx-light lg:text-left">
          {review}
        </p>

        <p className="text-deco-head-6 mb-1 text-center text-onyx lg:text-left">
          {reviewer.title}
        </p>
        <p className="text-body-4 text-center text-quicksilver lg:text-left">
          {reviewer.subtitle}
        </p>
      </div>
    </div>
  );
};
