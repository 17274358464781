import { useCallback, useMemo, useState } from "react";
import { isEmpty } from "lodash";

import { Arrow } from "./Arrow";
import { Checkmark } from "./Checkmark";
import { CtaButton } from "../CtaButton";
import { PricingOptionType, PricingPlansType, PricingPlanType } from "./types";
import { BestDealGraphic } from "../icons/bestDealGraphic";
import { Block } from "../block";
import { SectionIntros } from "../SectionIntro";
import { uuid } from "../../utils/uuid";

type Props = PricingPlansType;
export default function PricingPlans({ pricingPlans, sectionIntro }: Props) {
  // memos
  /////
  // NOTE: We can't use `pricingPlans` directly here, as we need to play with displaying/hidding values depending on user selection.
  // Thus, creating a `newPricingPlans` (with visibility props) based on `pricingPlans` is necessary.
  const defaultPricingPlans = useMemo(() => {
    if (isEmpty(pricingPlans)) return [];

    return pricingPlans?.map((pp) => {
      const pricingOptions = isEmpty(pp.pricingOptions)
        ? []
        : (pp.pricingOptions as Array<PricingOptionType>)
            .filter((pp) => pp.shouldDisplay)
            .map((po, poIndex) => ({
              ...po,
              _id: po._id || uuid(), // Use existing id or create a new one
              _isVisible: poIndex === 0, // The first option _always_ starts visible
            }));

      const newPricingPlan = {
        ...pp,
        _id: pp._id || uuid(), // Use existing id or create a new one
        pricingOptions,
      };

      return newPricingPlan;
    });
  }, [pricingPlans]);

  // states
  /////
  const [newPricingPlans, setNewPricingPlans] = useState(defaultPricingPlans);

  // callbacks
  /////
  const findPricingOption = useCallback(
    (pricingPlan: PricingPlanType, pricingOption: PricingOptionType) => {
      const foundPricingPlan = newPricingPlans?.find(
        (pp) => pp._id === pricingPlan._id,
      );
      const foundPricingOption = foundPricingPlan?.pricingOptions.find(
        (po) => po._id === pricingOption._id,
      );

      return foundPricingOption;
    },
    [newPricingPlans],
  );

  const changePricingOption = useCallback(
    (pricingPlan: PricingPlanType, pricingOption: PricingOptionType) => () => {
      setNewPricingPlans((prevPricingPlans) => {
        return prevPricingPlans?.map((pp) => {
          if (pp._id !== pricingPlan._id) return pp; // Don't bother with pricing plans that are not related to the one the user is interacting with

          const pricingOptions = isEmpty(pp.pricingOptions)
            ? []
            : (pp.pricingOptions as Array<PricingOptionType>).map((po) => ({
                ...po,
                _id: uuid(),
                _isVisible: po._id === pricingOption._id, // Change the respective pricing option for that pricing plan
              }));
          const newPricingPlan = { ...pp, pricingOptions, _id: uuid() };

          return newPricingPlan;
        });
      });
    },
    [setNewPricingPlans],
  );

  const hasMultiplePricingOptions = useCallback(
    (pricingPlan: PricingPlanType) => {
      if (isEmpty(pricingPlan) || isEmpty(pricingPlan.pricingOptions))
        return false;
      return (
        (pricingPlan.pricingOptions as Array<PricingOptionType>).length > 1
      );
    },
    [],
  );

  const pricingOptionVisibility = useCallback(
    (pricingPlan: PricingPlanType, pricingOption: PricingOptionType) => {
      const foundPricingOption = findPricingOption(pricingPlan, pricingOption);
      return foundPricingOption?._isVisible ? "" : "hidden";
    },
    [newPricingPlans],
  );

  const pricingOptionEmphasis = useCallback(
    (pricingPlan: PricingPlanType, pricingOption: PricingOptionType) => {
      const foundPricingOption = findPricingOption(pricingPlan, pricingOption);
      return foundPricingOption?._isVisible
        ? `bg-${pricingPlan.colorway} text-white`
        : "";
    },
    [findPricingOption],
  );

  return (
    <Block>
      <SectionIntros.forPricingPlans {...sectionIntro} />

      <div className="grid grid-flow-row gap-12 pt-6 lg:auto-cols-[minmax(min-content,1fr)] xl:grid-flow-col">
        {newPricingPlans?.map((pricingPlan) => (
          <div
            key={pricingPlan._id}
            className="relative flex flex-col rounded-[20px] bg-backgroundgray px-9 py-6 drop-shadow-[3px_3px_0_rgba(0,0,0,0.1)]"
          >
            {pricingPlan.ribbon?.shouldDisplay && (
              <div className="absolute right-[-30px] top-[-32px]">
                <BestDealGraphic className={`text-${pricingPlan.colorway}`} />
              </div>
            )}

            <h3 className="text-deco-head-5">
              <strong>
                <em>{pricingPlan.title}</em>
              </strong>
            </h3>

            <p className="text-body-2 py-3">{pricingPlan.subtitle}</p>

            {pricingPlan.pricingOptions?.map((currentPricingOption) => (
              <div
                key={currentPricingOption._id}
                className={`${pricingOptionVisibility(
                  pricingPlan,
                  currentPricingOption,
                )} flex flex-col`}
              >
                <div className="flex flex-row items-end gap-3">
                  <h2
                    className={`text-deco-head-3 text-${pricingPlan.colorway}`}
                  >
                    <strong>
                      <em>{currentPricingOption.headline?.highlighted}</em>
                    </strong>
                  </h2>

                  <div className="flex flex-col">
                    <p className="text-body-3 font-[500] leading-[1.3] text-onyx">
                      {currentPricingOption.headline?.asideTitle}
                    </p>
                    <p className="text-body-4 italic leading-[1.3] text-onyx-light">
                      {currentPricingOption.headline?.asideSubtitle}
                    </p>
                  </div>
                </div>

                {hasMultiplePricingOptions(pricingPlan) && (
                  <div className="mt-6 grid grid-rows-[auto,auto] gap-1 md:grid-cols-[auto,1fr]">
                    <div className="mx-auto mb-auto flex flex-row gap-1 rounded-full border border-platinum bg-white px-1 py-1 md:mx-0">
                      {pricingPlan.pricingOptions?.map((pricingOption) => (
                        <span
                          key={pricingOption._id}
                          className={`${pricingOptionEmphasis(
                            pricingPlan,
                            pricingOption,
                          )} text-body-4 cursor-pointer rounded-full px-2 py-[2px] text-[12px] font-[600] text-onyx md:px-5`}
                          onClick={changePricingOption(
                            pricingPlan,
                            pricingOption,
                          )}
                        >
                          {pricingOption.label}
                        </span>
                      ))}
                    </div>

                    {pricingPlan?.pricingOptionsCallout?.shouldDisplay && (
                      <div className="mt-3 flex flex-row-reverse items-start md:mt-0 md:flex-col lg:flex-row">
                        <Arrow
                          className={`m-o h-auto w-full max-w-[27px] rotate-90 md:rotate-0 text-${pricingPlan.colorway}`}
                        />

                        <p
                          className={`text-handwriting-4 text-${pricingPlan.colorway} ml-3 max-w-[240px]`}
                        >
                          {pricingPlan?.pricingOptionsCallout?.text}
                        </p>
                      </div>
                    )}
                  </div>
                )}

                <div className="my-6">
                  {currentPricingOption?.benefits?.map((benefits, i) => (
                    <div key={i} className="align-center mb-3 flex flex-col">
                      {benefits.heading && (
                        <h3 className="text-deco-head-5">
                          <strong>
                            <em>{benefits.heading}</em>
                          </strong>
                        </h3>
                      )}

                      <div
                        className={`grid grid-cols-[auto,1fr] text-${pricingPlan.colorway} items-start`}
                      >
                        {benefits.displayCheckMark && (
                          <Checkmark className="mr-2 h-[16px] w-[18px]" />
                        )}

                        <div className="flex flex-col">
                          {!!benefits?.firstLine && (
                            <p
                              className={`${
                                benefits.firstLine?.size || "text-body-4"
                              } font-[700] text-onyx`}
                            >
                              {benefits.firstLine?.text}
                            </p>
                          )}
                          {!!benefits.secondLine && (
                            <p className="text-body-4 text-onyx">
                              {benefits.secondLine}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}

            <div className="mt-auto flex flex-col gap-6 md:flex-row">
              {pricingPlan?.ctaButton1?.shouldDisplay && (
                <CtaButton
                  classNames="!w-full inline-grid self-center"
                  colorway={pricingPlan.colorway}
                  {...pricingPlan?.ctaButton1}
                />
              )}

              {pricingPlan?.ctaButton2?.shouldDisplay && (
                <CtaButton
                  classNames="!w-full inline-grid self-center"
                  colorway={pricingPlan.colorway}
                  {...pricingPlan?.ctaButton2}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </Block>
  );
}
