export const H3 = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style: any;
}) => (
  <h3 className="text-deco-head-4 text-dark my-6" style={style}>
    <strong>
      <em>{children}</em>
    </strong>
  </h3>
);
