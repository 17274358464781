import { q, z } from "groqd";

import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";
import { LayoutVariants } from "./enums";

export const sectionIntroSelection = {
  ...sanityInternalsSelection,
  heading: z.nullable(z.string()).optional(),
  intro: z.nullable(q.contentBlocks()).optional(),
  overline: z.nullable(z.string()).optional(),
  shouldDisplay: q.boolean().optional(),
  layoutVariant: z.nativeEnum(LayoutVariants).optional(),
};
