export const Checkmark = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0301 0.486297C16.4246 -0.130635 15.4337 -0.166894 14.7914 0.413707C13.7638 1.3754 12.8463 2.31889 11.9472 3.31685C11.048 4.3148 10.1672 5.31276 9.32311 6.36514C8.24045 7.70786 7.19451 9.12313 6.22196 10.5566L2.80885 6.5103C2.29502 5.9115 1.39593 5.76639 0.716949 6.20187C-0.0170519 6.67362 -0.218887 7.65344 0.258199 8.36108C0.258199 8.36108 4.24015 14.3851 4.77231 15.129C5.19439 15.7096 5.70814 16 6.40546 16C7.15779 16 7.68996 15.7097 8.16706 14.9295C8.9194 13.6593 10.7177 10.5747 12.1857 8.5244C12.9197 7.49019 13.6904 6.4922 14.4978 5.49425C15.2869 4.51442 16.1493 3.53463 16.9751 2.66369L17.0117 2.62742C17.599 2.02851 17.599 1.08498 17.0301 0.486204L17.0301 0.486297Z"
      fill="currentColor"
    />
  </svg>
);
