import { Colorways } from "src/theme";
import { SectionIntroType } from "./types";
import { Heading } from "./Heading";
import { RichTextSimpleComponent } from "../RichTextSimpleComponent";
import { LayoutVariants } from "./enums";

type Props = SectionIntroType & {
  colorway: Colorways;
};
export default function SectionIntro({
  layoutVariant,
  shouldDisplay,
  colorway,
  heading,
  intro,
  overline,
}: Props) {
  if (!shouldDisplay) return null;

  if (layoutVariant === LayoutVariants.BlackAndWhite)
    return (
      <div className={`mx-auto text-center`}>
        {!!overline && <p className="text-deco-head-6 text-onyx">{overline}</p>}

        {!!heading && (
          <h1 className="text-deco-head-3">
            <strong>
              <em>{heading}</em>
            </strong>
          </h1>
        )}

        {!!intro && <RichTextSimpleComponent content={intro} />}
      </div>
    );

  return (
    <div
      className={`mx-auto mb-9 mt-9 text-center md:w-2/3 xl:w-1/2 ${colorway}-colorway`}
    >
      {!!heading && <Heading colorway={colorway}>{heading}</Heading>}
      {!!overline && <p className="text-overline-2 text-onyx">{overline}</p>}
      {!!intro && (
        <RichTextSimpleComponent colorway={colorway} content={intro} />
      )}
    </div>
  );
}

export const SectionIntros = {
  forPricingPlans: function PricingPlansSectionIntro({
    shouldDisplay,
    heading,
    intro,
    overline,
  }: SectionIntroType) {
    if (!shouldDisplay) return null;

    return (
      <div className={`mx-auto text-center`}>
        {!!overline && <p className="text-overline-2 text-onyx">{overline}</p>}

        {!!heading && (
          <h1 className="text-deco-head-4">
            <strong>
              <em>{heading}</em>
            </strong>
          </h1>
        )}

        {!!intro && <RichTextSimpleComponent content={intro} />}
      </div>
    );
  },
};
