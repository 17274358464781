export const Quotes = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="65"
    height="43"
    viewBox="0 0 65 43"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.899824 16.025C1.87495 22.55 7.42482 27.2 13.7248 27.5C12.1499 33.3501 8.32495 35.675 3.67482 37.025C3.22488 37.1749 3.29982 37.6251 3.29982 37.6251L4.04983 42.275C4.04983 42.275 4.12477 42.65 4.72489 42.575C20.7749 40.775 31.6499 28.55 29.6249 13.7C27.7501 3.42502 19.7255 -0.549976 12.2999 0.500024C4.87489 1.69996 -0.225177 8.6 0.899824 16.025ZM48.8062 27.5C47.3062 33.3501 43.3311 35.675 38.7562 37.025C38.3063 37.1749 38.3812 37.6251 38.3812 37.6251L39.0563 42.275C39.0563 42.275 39.1312 42.65 39.7314 42.575C55.7814 40.8502 66.5813 28.625 64.7063 13.775C62.7564 3.42502 54.6563 -0.549979 47.3063 0.500021C39.8814 1.69996 34.7814 8.60002 35.9814 16.025C36.9562 22.625 42.4312 27.275 48.8062 27.5Z"
      fill="currentColor"
    />
  </svg>
);
