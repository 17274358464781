import { Colorways } from "../../theme";

export const H2 = ({
  children,
  colorway,
  style,
}: {
  children: React.ReactNode;
  colorway: Colorways;
  style: any;
}) => (
  <h2 className={`text-deco-head-2 my-6 text-${colorway}`} style={style}>
    <strong>
      <em>{children}</em>
    </strong>
  </h2>
);
