import React from "react";
import Image from "next/image";
import { isEmpty } from "lodash";

import { Block } from "../block";
import { getImageURL } from "../../utils/getImageURL";
import { Colorways } from "../../theme";
import { RichTextSimpleComponent } from "../RichTextSimpleComponent";
import {
  Backgrounds,
  HeadingStyle,
  ImagePlacement,
  LayoutVariant,
} from "./enums";
import { H2 } from "./H2";
import { H3 } from "./H3";
import { FeatureBlock } from "./FeatureBlock";
import {
  CtaButton,
  CtaButtonCallout,
  LayoutVariant as CtaButtonLayout,
  CtaButtonType,
} from "../CtaButton";
import { MultiColumnSelectionType } from "./types";
import { SectionIntro } from "../SectionIntro";
import { getImageDimensions } from "@sanity/asset-utils";
import { Checkmark } from "./Checkmark";

const FEATURE_BLOCK_SIZES = {
  [LayoutVariant.SingleColumn]: "grid-cols-1",
  [LayoutVariant.MultiColumn]: "grid-cols-[50%_50%]",
};

const GRID_SIZES = (imagePlacement: ImagePlacement) => ({
  [LayoutVariant.SingleColumn]:
    imagePlacement === ImagePlacement.Left
      ? "lg:grid-cols-[60%_40%]"
      : "lg:grid-cols-[40%_60%]",
  [LayoutVariant.MultiColumn]: "lg:grid-cols-[50%_50%]",
});

const CONTENT_PLACEMENTS = {
  [ImagePlacement.Left]: "lg:order-2 lg:ml-9",
  [ImagePlacement.Right]: "lg:order-1 lg:mr-9",
};

const IMAGE_PLACEMENTS = {
  [ImagePlacement.Left]: "lg:order-1",
  [ImagePlacement.Right]: "lg:order-2",
};

const HEADING_COMPONENTS = {
  [HeadingStyle.H2]: H2,
  [HeadingStyle.H3]: H3,
};

const BACKGROUNDS = {
  [Backgrounds.Moms]: "bg-moms-gradient",
  [Backgrounds.Default]: "bg-default-gradient",
  [Backgrounds.Families]: "bg-families-gradient",
  [Backgrounds.Pros]: "bg-pros-gradient",
  [Backgrounds.None]: "",
  [Backgrounds.LightGray]: "bg-transition-gradient",
};

type Props = MultiColumnSelectionType & {
  colorway: Colorways;
};
export default function MultiColumnSection({
  benefitsBlock,
  config,
  colorway,
  intro,
  image,
  primaryCTA,
  secondaryCTA,
  sectionIntro,
  featureBlocks,
  heading,
  overline,
}: Props) {
  const HeadingComponent = HEADING_COMPONENTS[config.headingStyle];
  const imagePlacement = IMAGE_PLACEMENTS[config.imagePlacement];
  const contentPlacement = CONTENT_PLACEMENTS[config.imagePlacement];
  const gridSize = GRID_SIZES(config.imagePlacement)[config.layoutVariant];
  const featureBlocksSize = FEATURE_BLOCK_SIZES[config.layoutVariant];
  const background = BACKGROUNDS[config.background];

  const shouldOverride =
    !!background && config.background !== Backgrounds.LightGray;
  const textStyleOverride = shouldOverride
    ? { color: "#FFF", WebkitTextFillColor: "#fff" }
    : {};
  const textClassNameOverride = shouldOverride ? "text-white-override" : "";

  const getCtaButtonClassNames = (ctaButton: CtaButtonType) => {
    if (!shouldOverride) return "";
    if (ctaButton.layoutVariant === CtaButtonLayout.Primary)
      return "bg-white/15 border-2 border-white/[.01]";
    if (ctaButton.layoutVariant === CtaButtonLayout.Secondary)
      return "text-white border-white";

    return "";
  };

  const primaryCtaClassnames = getCtaButtonClassNames(primaryCTA);
  const secondaryCtaClassnames = getCtaButtonClassNames(secondaryCTA);
  const ctaCalloutArrowColor = textClassNameOverride || `text-${colorway}`;

  const { width, height } = getImageDimensions({
    ...image,
    asset: image.asset || null,
  });

  const widthCorrected = image.is2x ? width / 2 : width;
  const heightCorrected = image.is2x ? height / 2 : height;

  return (
    <>
      <Block flushBottom>
        {sectionIntro?.shouldDisplay && (
          <SectionIntro colorway={colorway} {...sectionIntro} />
        )}
      </Block>

      <Block flushTop={sectionIntro?.shouldDisplay} className={`${background}`}>
        <div className="grid grid-rows-[auto]">
          <div
            className={`m-auto grid grid-flow-row lg:grid-flow-col ${gridSize}`}
          >
            <div className={`${imagePlacement} align-center flex lg:m-auto`}>
              {!!image?.asset && (
                <Image
                  width={widthCorrected}
                  height={heightCorrected}
                  style={{
                    margin: "0 auto",
                  }}
                  sizes="100vw"
                  className={`max-w-full`}
                  src={getImageURL(image).url()}
                  alt="multi column section image"
                  quality={90}
                />
              )}
            </div>

            <div className={`${contentPlacement} mt-8 ${colorway}-colorway`}>
              {!!overline && (
                <p className="text-overline-2" style={textStyleOverride}>
                  {overline}
                </p>
              )}

              {!!heading && (
                <HeadingComponent colorway={colorway} style={textStyleOverride}>
                  {heading}
                </HeadingComponent>
              )}

              {!!intro && (
                <div className="my-8">
                  <RichTextSimpleComponent
                    colorway={colorway}
                    content={intro}
                    className={textClassNameOverride}
                  />
                </div>
              )}

              {!isEmpty(featureBlocks) && (
                <div className={`mt-8 grid ${featureBlocksSize}`}>
                  {featureBlocks?.map((fb, i) => (
                    <FeatureBlock
                      className={textClassNameOverride}
                      colorway={colorway}
                      heading={fb.heading}
                      body={fb.body}
                      key={i}
                    />
                  ))}
                </div>
              )}

              {!isEmpty(benefitsBlock) && (
                <div>
                  {!!benefitsBlock.pre && (
                    <p className="text-body-2 font-[700] text-onyx">
                      {benefitsBlock.pre}
                    </p>
                  )}

                  {!!benefitsBlock.heading && (
                    <p className="text-deco-head-6 my-6 text-onyx">
                      {benefitsBlock.heading}
                    </p>
                  )}

                  {!isEmpty(benefitsBlock?.benefits) &&
                    benefitsBlock?.benefits?.map((benefit, i) => (
                      <div key={i} className="mt-3 flex flex-row">
                        <Checkmark
                          className={`mr-2 h-[16px] w-[18px] text-${colorway}`}
                        />
                        <p className="text-body-4 text-onyx">{benefit}</p>
                      </div>
                    ))}
                </div>
              )}

              <div className="mt-8 flex grid-cols-[auto_1fr] grid-rows-[auto_1fr] flex-col gap-3 md:grid">
                {primaryCTA.shouldDisplay && (
                  <div className="order-1 mb-3 md:mb-0">
                    <CtaButton
                      colorway={colorway}
                      classNames={primaryCtaClassnames}
                      layoutVariant={primaryCTA.layoutVariant}
                      link={primaryCTA.link}
                      text={primaryCTA.text}
                    />
                  </div>
                )}

                {secondaryCTA.shouldDisplay && (
                  <div className="order-3 md:order-2">
                    <CtaButton
                      colorway={colorway}
                      classNames={secondaryCtaClassnames}
                      layoutVariant={secondaryCTA.layoutVariant}
                      link={secondaryCTA.link}
                      text={secondaryCTA.text}
                    />
                  </div>
                )}

                {primaryCTA.callout?.shouldDisplay && (
                  <CtaButtonCallout
                    arrowClassNames={ctaCalloutArrowColor}
                    colorway={colorway}
                    callout={primaryCTA.callout}
                    textClassNames={textClassNameOverride}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Block>
    </>
  );
}
