import { Colorways } from "../../theme";
import { RichTextSimpleComponent } from "../RichTextSimpleComponent";
import { FeatureBlockType } from "./types";

export const FeatureBlock = ({
  className,
  colorway,
  body,
  heading = "",
}: FeatureBlockType & { className?: string; colorway: Colorways }) => {
  return (
    <div className="mr-6">
      <p className={`text-head-7 m-0 text-onyx ${className}`}>{heading}</p>
      {!!body && (
        <RichTextSimpleComponent
          colorway={colorway}
          content={body}
          className={className}
        />
      )}
    </div>
  );
};
