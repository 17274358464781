import { q, z } from "groqd";

export const sanityInternalsSelection = {
  // _key: z.nullable(q.string().optional()),
  _type: z.nullable(q.string().optional()),
  // _rev: z.nullable(q.string().optional()),
  // _ref: z.nullable(q.string().optional()),
  _id: z.nullable(q.string().optional()),
  // _updatedAt: z.nullable(q.string().optional()),
};
