import { PortableText, PortableTextComponents } from "@portabletext/react";
import { getClassName } from "@pairtreefamily/utils";
import { ListItem, OrderedList, UnorderedList } from "./list";
import { TypeFromSelection, q } from "groqd";

export type ColorEditor = {
  colorName:
    | "darkblue"
    | "teal"
    | "green"
    | "lightgreen"
    | "peach"
    | "rust"
    | "colorway"
    | "colorway_gradient";
};

export const RichTextSelection = {
  content: q.contentBlocks(),
};

export type RichTextProps = TypeFromSelection<typeof RichTextSelection> & {
  colorway?: string;
  className?: string;
};

export function RichTextSimpleComponent(props: RichTextProps) {
  if (!props.content) {
    return null;
  }

  return (
    <div className={`${props.colorway}-colorway ${props.className}`}>
      <PortableText value={props.content} components={portableTextComponents} />
    </div>
  );
}

export const colorMap = {
  dark: "text-onyx",
  onyx: "text-onyx",
  darkblue: "text-darkblue",
  teal: "text-teal",
  green: "text-green",
  lightgreen: "text-lightgreen",
  peach: "text-peach",
  rust: "text-rust",
  colorway: "rich-text-colorway",
  colorway_gradient: "rich-text-gradient",
};

// keys in the `types` object need to match document _type
const portableTextComponents: PortableTextComponents = {
  // keys in the `types` object correspond to _type attribute in schema
  // this handles all non-'block' types defined in blockContent

  // This corresponds to the 'list' field of the 'block' schema
  list: {
    bullet: ({ children }) => (
      <UnorderedList style="disc">{children}</UnorderedList>
    ),
    number: ({ children, value }) => (
      <OrderedList style={value.level === 1 ? "decimal" : "alpha"}>
        {children}
      </OrderedList>
    ),
    check: ({ children }) => (
      <UnorderedList style="check">{children}</UnorderedList>
    ),
  },
  listItem: {
    bullet: ({ children }) => <ListItem>{children}</ListItem>,
    number: ({ children }) => <ListItem>{children}</ListItem>,
    check: ({ children }) => <ListItem>{children}</ListItem>,
  },
  // This corresponds to the 'marks' field of the 'block' schema. Both
  // annotations and decorators are handled here
  marks: {
    link: ({ children, value }) => (
      <a href={value.href}>
        <span className="text-teal">{children}</span>
      </a>
    ),
    color: ({ value, children }) => {
      if (value.colorName in colorMap) {
        return (
          <span
            className={getClassName(
              colorMap[(value as ColorEditor)?.colorName],
            )}
          >
            {children}
          </span>
        );
      }
      return <span style={{ color: value.colorName }}>{children}</span>;
    },
  },

  // 'main' block content overrides. This corresponds to the 'styles' field of
  // the 'block' schema
  block: {
    h1: ({ children }) => <h1 className="text-deco-head-1 my-6">{children}</h1>,
    h2: ({ children }) => <h2 className="text-deco-head-2 my-2">{children}</h2>,
    h3: ({ children }) => <h3 className="text-deco-head-5 my-3">{children}</h3>,
    h4: ({ children }) => (
      <h4 className="text-head-6 my-3 text-onyx">{children}</h4>
    ),
    h5: ({ children }) => (
      <h5 className="text-head-7 my-3 text-onyx">{children}</h5>
    ),
    h6: ({ children }) => (
      <h6 className="text-head-8 my-3 text-onyx">{children}</h6>
    ),
    large: ({ children }) => <p className="text-body-1 my-4">{children}</p>,
    medium: ({ children }) => <p className="text-body-2 my-4">{children}</p>,
    normal: ({ children }) => <p className="text-body-3 my-4">{children}</p>,
    small: ({ children }) => <p className="text-body-4 my-4 ">{children}</p>,
    blockquote: ({ children }) => (
      <figure className="text-quotation-3 my-4 text-center md:my-8">
        {children}
      </figure>
    ),
    overline: ({ children }) => <p className="text-overline-2">{children}</p>,
  },
};
