export const Arrow = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="31"
    height="13"
    viewBox="0 0 31 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.2231 10.3366C26.4688 3.16736 14.8435 0.974455 8.33733 3.88343C6.1354 4.86794 3.58029 6.55717 1.92709 8.30667C1.12065 9.1601 3.13663 9.46694 3.71098 9.79208C5.00607 10.5253 9.16937 12.165 7.76124 11.6784C6.01905 11.0763 4.27687 10.4742 2.53469 9.87217C-1.1264 8.60694 5.03085 3.61984 6.32961 1.79932"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
